import React, { lazy } from 'react'
import history from './utils/history';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

import ProtectedRoute  from './pages/ProtectedRoute';
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy( () => import( './pages/Login' ) );


function App ()
{
  return (
    <>
      <Router history= {history}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {/* Place new routes over this */ }
         
          <ProtectedRoute  path="/app" component={Layout}/>
           {/* <Route path="/app" component={ Layout } />  */}
         
            <Route path="/login" component={Login} />
          
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
