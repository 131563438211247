import React from 'react'

function ThemedSuspense() {
  return (
     <div className="h-screen bg-gray-100 dark:bg-gray-700">
    <div className="flex items-center justify-center p-64">
      <div className="loader"></div>
      </div>
      </div>
    
 
  )
}

export default ThemedSuspense
