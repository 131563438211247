import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/authSlice';
import MessageReducer from './slices/messageSlice';


export const store = configureStore( {
    reducer: {
       auth : AuthReducer,
       message: MessageReducer,
  }
} );

