import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
// import { ToastContainer, Zoom } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { store } from './redux/store';

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(

  <Provider store={ store }>
   <Toaster
  position="top-center"
  reverseOrder={false}
  />
     {/* <ToastContainer transition={ Zoom } position="top-center" autoClose={ 3000 } /> */}
  <SidebarProvider>
    <Suspense fallback={ <ThemedSuspense /> }>
      <Windmill usePreferences>
        <App />
      </Windmill>
    </Suspense>
    </SidebarProvider>
    </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
