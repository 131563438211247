import axios from "axios";

const auth = axios.create({
  baseURL: "https://integrations.sambeapp.com/admin" 
} );


const login = async (email, password ) =>
{
   await auth
    .post('/login', {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const AuthService = {
  login,
  logout,
};

export default AuthService;





