import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../utils/authService";
import toast from 'react-hot-toast';


//AUTH Actions
const user = JSON.parse( localStorage.getItem( "user" ) );

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login( email, password );
      toast.success( "Successfully Logged In!" );
      return { user: data };
    
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch();
      toast.error( `Error Message: ${ message }` );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
} );


//AUTH SLICE

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };


const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
} );

const { reducer } = authSlice;
export default reducer;